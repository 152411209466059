

import {
  Component, Watch, Vue,
} from 'vue-property-decorator'
import { mapState } from 'vuex'
import _ from 'lodash'
import store from '@/store'
import {
  l, localeFromPath, themeSegmentFromUrl,
} from '@/utils/helpers'

import FourOhFour from '@/components/molecules/FourOhFour.vue'
import Loader from '@/components/atoms/Loader.vue'
import Hero from '@/components/molecules/Hero.vue'
import ContentSection from '@/components/organisms/ContentSection.vue'
import CuratedContentSection from '@/components/organisms/CuratedContentSection.vue'
import SectionTitle from '@/components/atoms/SectionTitle.vue'
import EducateFeature from '@/components/molecules/EducateFeature.vue'

import conf from '../../configuration'

@Component({
  components: {
    FourOhFour,
    Hero,
    ContentSection,
    CuratedContentSection,
    Loader,
    SectionTitle,
    EducateFeature,
  },
  // This is used by the Vue Meta package
  // to add dynamic meta information to HTML head
  // See also public/index.html
  metaInfo() {
    const {
      title, description, image, locale, imageAlt,
    } = this.$store.getters.activeTheme
    return {
      title,
      meta: [
        { name: 'description', content: description },

        // Open graph
        { property: 'og:title', content: title || conf.rssTitle },
        { property: 'og:description', content: description || conf.rssDescription },
        { property: 'og:url', content: window.location.href },
        {
          property: 'og:image',
          content: image ? `${conf.media.baseUrl}${conf.media.sourcePath}${conf.media.namespace}${_.startsWith(image, '/') ? image : `/${image}`}` : conf.rssFeedImage,
        },
        {
          property: 'og:image:alt',
          content: imageAlt || 'earth',
        },

        // Twitter
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:summary', content: description },
        { property: 'twitter:title', content: title || conf.rssTitle },
        { property: 'twitter:description', content: description || conf.rssDescription },
        { property: 'twitter:url', content: window.location.href },
        {
          property: 'twitter:image',
          content: image ? `${conf.media.baseUrl}${conf.media.sourcePath}${conf.media.namespace}${_.startsWith(image, '/') ? image : `/${image}`}` : conf.rssFeedImage,
        },
        {
          property: 'twitter:image:alt',
          content: imageAlt || 'earth',
        },
      ],
      htmlAttrs: {
        lang: locale,
      },
      // Remove the /en/ part from the canonical URL if the language is English
      // Make sure canonical URLs always end with a /
      link: [
        {
          rel: 'canonical',
          href: locale === 'en' && !this.$route.path.includes('/en/')
            ? `${conf.url}${_.replace(_.endsWith(this.$route.path, '/') ? this.$route.path : `${this.$route.path}/`, '/', '/en/')}`
            : `${conf.url}${_.endsWith(this.$route.path, '/') ? this.$route.path : `${this.$route.path}/`}`,
        },
      ],
    }
  },
  computed: mapState({
    contentNotFound: (state: any) => (
      state.status === 404
    ),
    theme: (state: any) => (
      (state.active && state.active.theme) ? state.active.theme.theme : undefined
    ),
    activeContent: (state: any) => (
      (state.active
      && state.active.theme
      && state.active.theme.content
      && state.active.theme.content.active
      && !_.isEmpty(state.active.theme.content.active)
      )
        ? state.active.theme.content.active
        : state.active.theme.content.passive // Return all content when filter result set is empty
    ),
    curatedContentSections: (state: any) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      ((state.active && state.active.theme && state.active.theme.theme)
        ? state.active.theme.theme.curated
        : undefined
      ),
  }),
  methods: {
    l,
  },
})
export default class Theme extends Vue {
  private locale: string

  private educateFeatures: Array<{}>

  constructor() {
    super()
    this.educateFeatures = []
    this.locale = ''
  }

  resetTagsTopicsAndContent() {
    try {
      store.dispatch('set', { key: 'active.theme.content.active', data: [] })
      store.dispatch('set', { key: 'active.theme.theme', data: {} })
      store.dispatch('set', { key: 'active.theme.content.passive', data: [] })
      store.dispatch('set', { key: 'active.theme.topics.active', data: [] })
      store.dispatch('set', { key: 'active.theme.topics.passive', data: [] })
      store.dispatch('set', { key: 'active.theme.tags.active', data: [] })
      store.dispatch('set', { key: 'active.theme.tags.passive', data: [] })
    } catch (err) {
      document.dispatchEvent(new Event('custom-render-trigger'))
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.resetTagsTopicsAndContent()
    if (this.$route) {
      this.locale = localeFromPath(this.$route.path)

      try {
        // Fetch the Theme from the API based on its slug
        store.dispatch(
          'fetch',
          `/?slug=${themeSegmentFromUrl(this.$route.path)}&&locale=${this.locale}`,
        )
          .then((result: any) => {
            if (result && result[0]) {
              // Set the fetched Theme as active in the VUEX store
              try {
                store.dispatch('set', { key: 'active.theme.theme', data: result[0] })
              } catch (error) {
                document.dispatchEvent(new Event('custom-render-trigger'))
              }
              if (this.checkIfEducateHome() && _.isEmpty(this.educateFeatures)) {
                try {
                  Promise.all([
                    store.dispatch(
                      'fetch',
                      '/?slug=feature-guides',
                    ),
                    store.dispatch(
                      'fetch',
                      '/?slug=feature-workshops',
                    ),
                  ]).then((res) => {
                    this.educateFeatures.push(...res[0], ...res[1])
                  })
                } catch (err) {
                  throw new Error(err)
                }
              }
              /* Fetch all related content types posts */
              if (!_.isEmpty(result[0].relatedContentTypes)) {
                const contentTypePromises = result[0].relatedContentTypes.map((contentType: any) => store.dispatch('fetch', `/?contentType=${contentType}&&locale=${this.locale}`))
                Promise.all(contentTypePromises)
                  .then((contentForType: any) => {
                    const allContentTypes = [].concat(...contentForType)
                    if (allContentTypes && allContentTypes[0]) {
                      try {
                        store.dispatch('set', { key: 'active.theme.content.passive', data: allContentTypes })
                      } catch (error) {
                        document.dispatchEvent(new Event('custom-render-trigger'))
                      }
                    }
                  })
                // Fetch all Topics from the API
                  .then(() => {
                    try {
                      store.dispatch(
                        'fetch',
                        `/?contentType=topic&&locale=${this.locale}`,
                      )
                        .then((topics: any) => {
                          if (topics && !_.isEmpty(topics)) {
                            try {
                            // Set all Topics in the VUEX store
                              store.dispatch('set', { key: 'content.topics', data: topics })
                              const { topicsFromThemeContent } = this.$store.getters
                              if (
                                _.isEmpty(topicsFromThemeContent)
                              || _.isUndefined(topicsFromThemeContent)
                              ) {
                                store.dispatch('set', { key: 'active.theme.topics.passive', data: [] })
                              } else {
                                try {
                                // Set Topics for this theme in VUEX store
                                  store.dispatch(
                                    'set', { key: 'active.theme.topics.passive', data: topicsFromThemeContent },
                                  )
                                } catch (error) {
                                  document.dispatchEvent(new Event('custom-render-trigger'))
                                }
                              }
                            } catch (error) {
                              try {
                                store.dispatch('set', { key: 'active.theme.topics.passive', data: [] })
                              } catch (err) {
                                document.dispatchEvent(new Event('custom-render-trigger'))
                              }
                              setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 3000)
                            }
                          }
                        })
                    } catch (err) {
                      document.dispatchEvent(new Event('custom-render-trigger'))
                    }
                  })
                // Fetch Tags
                  .then(() => {
                    try {
                      store.dispatch(
                        'fetch',
                        `/?contentType=tag&&locale=${this.locale}`,
                      )
                        .then((tags: any) => {
                          if (tags && !_.isEmpty(tags)) {
                            try {
                            // Set all Tags in the VUEX store
                              store.dispatch('set', { key: 'content.tags', data: tags })
                              const { tagsFromThemeContent } = this.$store.getters
                              if (
                                _.isEmpty(tagsFromThemeContent)
                              || _.isUndefined(tagsFromThemeContent)
                              ) {
                                store.dispatch('set', { key: 'active.theme.tags.passive', data: [] })
                              } else {
                                try {
                                // Set Tags for this Theme in the VUEX store
                                  store.dispatch(
                                    'set', { key: 'active.theme.tags.passive', data: tagsFromThemeContent },
                                  )
                                } catch (error) {
                                  document.dispatchEvent(new Event('custom-render-trigger'))
                                }
                              }
                            } catch (error) {
                              try {
                                store.dispatch('set', { key: 'active.theme.tags.passive', data: [] })
                              } catch (err) {
                                document.dispatchEvent(new Event('custom-render-trigger'))
                              }
                              setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 3000)
                            }
                          }
                        })
                    } catch (err) {
                      document.dispatchEvent(new Event('custom-render-trigger'))
                    }
                  })
                  .catch(
                    (error: string) => {
                      this.resetTagsTopicsAndContent()
                    },
                  )
              }
            }
          })
          .catch(
            (error: string) => {
              try {
                store.dispatch('set', { key: 'status', data: 404 })
              } catch (err) {
                document.dispatchEvent(new Event('custom-render-trigger'))
              }
            },
          )
      } catch (error) {
        document.dispatchEvent(new Event('custom-render-trigger'))
      }
      /* Tell the prerender-spa-plugin that data fetching is complete
      * and that prerendering can start, see vue.config.js.
      * Wait until all the stores variables are set and give
      * the render engine some time to render.
      */
      if (this.$store.state
          && this.$store.state.content
          && this.$store.state.content.topics
          && this.$store.state.content.tags
          && this.$store.state.active
          && this.$store.state.active.theme
          && this.$store.state.active.theme.topics
          && this.$store.state.active.theme.topics.passive
          && this.$store.state.active.theme.tags
          && this.$store.state.active.theme.tags.passive
          && this.$store.state.active.theme.theme
          && this.$store.state.active.theme.content) {
        setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 10000)
      }
    }
  }

  checkIfEducateHome() {
    const routeArr = this.$route.fullPath.split('/').filter(r => r.length)
    return routeArr[routeArr.length - 1] === 'learn'
  }
}
