

import {
  Component, Prop, Vue,
} from 'vue-property-decorator'
import ResponsiveImage from '@/components/atoms/ResponsiveImage.vue'
import Label from '@/components/atoms/Label.vue'

@Component({
  components: {
    ResponsiveImage,
    Label,
  },
})
export default class EducateFeature extends Vue {
  @Prop({ required: true }) private activeFeature!: {}
}
